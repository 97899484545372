var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table"},[(!_vm.data.length)?_c('div',{staticClass:"pt-4"},[_vm._t("empty")],2):_c('div',[_c('div',{staticClass:"grid-table mb-2",style:({ 'grid-template-columns': _vm.gridFractions })},_vm._l((_vm.columns),function(column,ic){return _c('small',{key:ic,staticClass:"text-secondary font-weight-bold",class:{
          'pl-2': ic === 0 && _vm.firstTitlePadding,
          'mr-1': ic === 0,
          'ml-1': ic === _vm.columns.length - 1,
          'mx-1': ic !== 0 && ic !== _vm.columns.length - 1
        }},[_vm._v(" "+_vm._s(column)+" ")])}),0),_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"grid-table item ",class:{
        'item-border': _vm.showSeparator,
        'py-3': !_vm.slim
      },style:({
        'grid-template-columns': _vm.gridFractions
      })},[_vm._t("default",null,{"item":item,"i":index})],2)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }