<template>
  <div id="app">
    <component :is="template">
      <router-view />
    </component>

    <snackbar />
  </div>
</template>

<script>
import { getToken } from "./setup/api";

export default {
  computed: {
    template() {
      return this.$route.meta.template;
    },
    isLogged() {
      return this.$store.state.user.logged;
    },
    showRouterView() {
      return !this.$route.meta.protected || this.isLogged;
    }
  },
  beforeMount() {
    if (!getToken() && this.$route.meta.protected) {
      this.$router.replace({ name: "login" });
    } else if (getToken()) {
      this.$store
        .dispatch("postLogin")
        .then(() => {
          if (this.$user.user) {
            this.$router.replace({ name: "sale" });
          }
        })
        .catch(() => {
          this.$router.replace({ name: "login" });
        });
      if (["/login", "/register"].includes(document.location.pathname)) {
        this.$router.replace({ name: "home" });
      }
    }
  },
  watch: {
    $route: function() {
      if (!this.isLogged && this.$route.meta.protected) {
        this.$router.replace({ name: "login" });
      }
      window.scrollTo(0, 0);
    }
  }
};
</script>
