import Vue from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import "vue-tel-input/dist/vue-tel-input.css";
import vClickOutside from "v-click-outside";
import VueApexCharts from "vue-apexcharts";
import BootstrapVue from "bootstrap-vue";
import VueTelInput from "vue-tel-input";
import VueTheMask from "vue-the-mask";
import VCalendar from "v-calendar";
import "moment/locale/pt-br";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/icomoon/style.css";
import "./assets/scss/app.scss";
import "./components";

import "./plugins";
import { init } from "./setup/api";

Vue.use(VueTelInput);
Vue.use(BootstrapVue);
Vue.use(CKEditor);
Vue.use(vClickOutside);
Vue.use(VueTheMask);
Vue.use(VueApexCharts);
Vue.use(VCalendar, {});

init();

Vue.config.productionTip = false;

Vue.prototype.$DEV_ENV = process.env.NODE_ENV === "development";

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
