export function toHumanDate(date) {
  return date
    .split("-")
    .reverse()
    .join("/");
}

export function getCurrencySymbol(currency) {
  let c = currency.toLowerCase();
  switch (c) {
    case "brl":
      return "R$";
    case "usd":
      return "US$";
    default:
      return "$";
  }
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ",",
  thousands = "."
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    // console.log(e);
  }
}

export function dayDiff(dateString) {
  var today = new Date();
  var date = new Date(dateString);

  // To calculate the time difference of two dates
  var differenceInTime = date.getTime() - today.getTime();

  // To calculate the no. of days between two dates
  return Math.floor(differenceInTime / (1000 * 3600 * 24));
}

export const formFields = (fields, initial = {}) => {
  const form = {};
  const errors = {};
  for (const i in fields) {
    const field = fields[i];
    form[field] =
      initial &&
      (initial[field] !== "" ||
        initial[field] !== undefined ||
        initial[field] !== null)
        ? initial[field]
        : "";
    errors[field] = [];
  }
  return { form, errors, loading: false };
};

export const removeMask = maskedValue => {
  return maskedValue
    .replace(/\s/g, "")
    .replace(/-/g, "")
    .replace(/\(/g, "")
    .replace(/\)/g, "")
    .replace(/\D/g, "")
    .trim();
};

export const getArrayOfDaysInMonth = (year, month) => {
  var numDaysInMonth, daysInWeek, daysIndex, index, day, daysArray;

  numDaysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  daysInWeek = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"];
  daysIndex = { Sun: 0, Mon: 1, Tue: 2, Wed: 3, Thu: 4, Fri: 5, Sat: 6 };
  let yearPrepared = month === 1 ? year - 1 : year;

  index =
    daysIndex[new Date(yearPrepared, month - 1, 1).toString().split(" ")[0]];

  daysArray = [];

  for (day = 1; day <= numDaysInMonth[month - 1]; day++) {
    if (day === 1) {
      const end = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = end - 1; weekIndex >= 0; weekIndex--) {
        const beforeMonthDaysCount =
          month === 1 ? numDaysInMonth[11] : numDaysInMonth[month - 2];
        daysArray.push({
          day: beforeMonthDaysCount - weekIndex,
          isCurrentMonth: false,
          monthPosition: "before"
        });
      }

      daysArray.push({ day, isCurrentMonth: true });
    } else if (day === numDaysInMonth[month - 1]) {
      daysArray.push({ day, isCurrentMonth: true, monthPosition: "current" });

      const start = daysInWeek.findIndex(d => d === daysInWeek[index]);

      for (let weekIndex = 1; weekIndex <= 7 - start; weekIndex++) {
        daysArray.push({
          day: weekIndex,
          isCurrentMonth: false,
          monthPosition: "next"
        });
      }
    } else {
      daysArray.push({ day, isCurrentMonth: true });
    }

    index++;
    if (index == 7) index = 0;
  }

  return daysArray;
};

export const getYearRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const clearObject = object => {
  for (const i in object) {
    if (object[i] === "" || object[i] === undefined || object[i] === null) {
      delete object[i];
    }
  }
  return object;
};

export const findLastIndex = (list, predicate) => {
  let l = list.length;
  while (l--) {
    if (predicate(list[l], l, list)) return l;
  }
  return -1;
};

export const normalizeText = text =>
  `${text}`
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

export const isValid = (value, type) => {
  if (type == "email") {
    return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi.test(
      `${value}`
    );
  }

  return !!value;
};
