<template>
  <div class="component-input-image" :class="{ slim: isSlim }">
    <div class="row align-items-end mb-3">
      <div class="col-6 col-lg-12">
        <div :class="{ 'container-upload-image': !value }">
          <!-- Upload -->
          <div class="upload-image" :class="{ 'd-none': value }">
            <i class="icon-image" :class="{ 'mb-4 mb-lg-2': !isSlim }"></i>
            <div class="text-primary font-weight-bold d-lg-none">
              {{ title.toUpperCase() }}
            </div>
            <div class="text-primary font-weight-bold d-none d-lg-block">
              {{ title }}
            </div>
            <span class="text-secondary small-text d-none d-lg-block">
              Arraste para cá ou selecione
            </span>
            <input
              :id="id"
              type="file"
              accept="image/*"
              class="input-file"
              @change="fileChange($event.target.files)"
            />
          </div>
          <!-- Success -->
          <div v-if="value" class="container-image">
            <img :src="value" />
            <button class="btn edit-image" type="button" @click="changeImage">
              <i class="icon-edit"></i>
            </button>
            <button class="btn remove-image" type="button" @click="resetImage">
              <i class="icon-x"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-6 col-lg-8 pl-0 d-lg-none" v-if="!isSlim">
        <div class="image-description p-2 mt-3">
          <span class="text-secondary small-text">
            {{ description }}
          </span>
        </div>
      </div>
    </div>
    <div class="image-description p-2 mt-3 d-none d-lg-block" v-if="!isSlim">
      <span class="text-secondary small-text">
        {{ description }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "input-image-id"
    },
    returnType: {
      type: String,
      default: "base64"
    },
    title: {
      type: String,
      default: "Imagem do Produto"
    },
    description: {
      type: String,
      default:
        "Use uma imagem no formato JPG ou PNG com no máximo 5MB de tamanho. Dimensões recomendadas: 800x800px."
    },
    value: {
      type: [File, String],
      default: null
    },
    isSlim: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    isValid() {
      let valid = false;
      for (const key in this.form) {
        valid = Array.isArray(this.form[key])
          ? this.form[key].length
          : !!this.form[key];
      }
      return valid;
    }
  },
  methods: {
    changeImage() {
      document.getElementById(this.id).click();
    },
    resetImage() {
      this.$emit("input", null);
      document.getElementById(this.id).type = "text";
      document.getElementById(this.id).type = "file";
    },
    fileChange(files) {
      if (!files) return;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(files[0].size) / Math.log(1024)));
      const size =
        Math.round(files[0].size / Math.pow(1024, i), 2) + " " + sizes[i]; // convert to mb and show unit meansurement

      if (size.match("MB") && /([^MB]*)MB/.exec(size)[1] > 5) {
        this.$message.error("O arquivo deve ter no máximo 5 mb");
        return;
      }

      if (this.returnType == "base64") {
        this.getImage(files[0]).then(img => {
          this.$emit("input", img);
        });
      } else {
        this.$emit("input", files[0]);
      }

      this.$emit("change-file", files[0]);
    },
    getImage(file) {
      return new Promise((resolve, reject) => {
        const fReader = new FileReader();
        const img = document.createElement("img");

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const dataURL = img.src;
      return dataURL;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.component-input-image {
  .upload-image {
    padding: 56px 16px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgba($color: #9155ec, $alpha: 0.05);
    border-radius: 4px;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50px;
      transform: translateX(-50%);
    }

    i {
      font-size: 56px;
      margin-bottom: 30px;
      color: $primary;
    }

    @media screen and (max-width: 991px) {
      padding: 26px 10px 22px;

      div {
        font-size: 12px;
      }

      i {
        font-size: 46px;
        margin-bottom: 10px;
        color: #9155ec;
      }
    }
  }

  .container-image {
    display: flex;

    img {
      width: 225px;
      max-width: 100%;
      border-radius: 4px;
    }

    .edit-image,
    .remove-image {
      min-width: 24px;
      min-height: 24px;
      max-width: 24px;
      max-height: 24px;
      background-color: #393444;
      position: absolute;
      color: #fff;

      display: flex;
      justify-content: center;
      align-items: center;

      i {
        max-width: 24px;
      }
    }

    .edit-image {
      left: calc(100% - 48px - 16px - 8px - 8px); // sorry about that 😅
      top: 8px;
    }
    .remove-image {
      left: calc(100% - 24px - 16px - 8px);
      top: 8px;
    }
  }

  .container-upload-image {
    border: 1px dashed #d2d0d8;
    border-radius: 4px;
    height: 225px;
    width: 225px;
    max-width: 100%;
    @media screen and (max-width: 991px) {
      height: 165px;
      width: 165px;
    }
  }

  .image-description {
    background: #fff;
    border-radius: 8px;
    line-height: 15px;
    width: 225px;
    max-width: 100%;
    @media screen and (max-width: 991px) {
      width: 165px;
    }
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 100%;
    height: 100%;
    margin-bottom: 10%;
    position: absolute;
    cursor: pointer;
  }

  &.slim {
    .upload-image {
      padding: 12px 24px 9px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      i {
        font-size: 24px;
        margin-bottom: 0px;
        margin-right: 10px;
      }
    }

    .text-primary {
      font-size: 14px;
    }
    .container-upload-image {
      border: 1px solid #d2d0d8;
      height: 64px;
      width: 100%;
    }

    .input-file {
      margin-bottom: 0;
      margin-left: -5%;
    }
  }
}
</style>
