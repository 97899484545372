<template>
  <div class="component-paginator">
    <div class="d-flex align-items-center">
      <button
        class="btn btn-sm"
        @click="request('previous')"
        :disabled="!pagination.previous"
      >
        <i class="icon-angle-left" />
      </button>
      <div class="d-flex align-items-center flex-fill">
        <span class="mr-2">
          {{ page }}
        </span>
        de {{ Math.ceil(pagination.count / step) }} páginas
      </div>
      <button
        class="btn btn-sm"
        @click="request('next')"
        :disabled="!pagination.next"
      >
        <i class="icon-angle-right" />
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pagination: {
      type: Object,
      default: () => ({
        next: null,
        previous: null,
        count: 0
      })
    }
  },
  data() {
    return {
      page: 1,
      offset: 0,
      limit: 20,
      step: 20
    };
  },
  methods: {
    request(direction) {
      let offset, page;

      if (direction === "next") {
        offset = this.offset + this.step;
        page = this.page + 1;
      } else {
        offset = this.offset - this.step;
        page = this.page - 1;
      }

      if (this.pagination.count > offset && offset >= 0) {
        this.page = page;
        this.offset = offset;
        this.$emit("on-update", offset);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.component-paginator {
  min-width: 210px;
  i {
    font-size: 24px;
  }

  span {
    background: transparent;
    border: 1px solid #d2d0d8;
    border-radius: 4px;
    padding: 5px 15px;
  }
}
</style>
