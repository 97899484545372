<template>
  <card v-if="isAvailabe">
    <div class="exam-available-component">
      <div class="row align-items-center">
        <div class="col-lg-1 text-center text-lg-start">
          <h6><i class="icon-trophy"></i></h6>
        </div>
        <div class="col-lg-6 text-center text-lg-start mb-4 mb-lg-0">
          <h5>{{ text }}</h5>
        </div>
        <div class="col-lg-5">
          <div class="d-flex justify-content-center justify-content-lg-end">
            <router-link
              class="btn btn-primary"
              :to="{
                name: 'test-exam',
                params: {
                  class_id: $user.classInfo.id,
                  id: $user.classInfo.exam_available_id
                }
              }"
            >
              Clique para acessar a prova
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </card>
  <div v-else></div>
</template>
<script>
import get from "lodash/get";
import moment from "moment";
export default {
  name: "ExamAvailable",
  data() {
    return {
      exam_module_info: null
    };
  },
  computed: {
    text() {
      if (this.exam_module_info) {
        return `Prova do módulo ${this.exam_module_info.name} liberada até: ${this.$user.classInfo.exam_expire_at}`;
      }
      return "";
    },
    isAvailabe() {
      if (
        !this.$user.classInfo ||
        !this.$user.classInfo.exam_available_id ||
        !this.$user.classInfo.exam_expire_at
      ) {
        return false;
      }

      const expire_at = get(this.$user.classInfo, "exam_expire_at", false);
      if (expire_at) {
        const diff =
          moment(expire_at, "DD/MM/YYYY").unix() - new Date().getTime();
        return diff < 0;
      }

      return false;
    }
  },
  mounted() {
    this.dispatch();
  },
  methods: {
    async dispatch() {
      if (this.$user.classInfo.exam_available_id) {
        this.exam_module_info = await this.$store.dispatch(
          "content/getModule",
          this.$user.classInfo.exam_available_id
        );
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.exam-available-component {
  .icon-trophy {
    font-size: 50px;
  }
}
</style>
