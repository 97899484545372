export default {
  setModules(state, modules) {
    state.modules = modules;
  },
  setLessons(state, lessons) {
    state.lessons = lessons;
  },
  setLesson(state, lesson) {
    state.lesson = lesson;
  },
  setLessonsCount(state, lessonsCount) {
    state.lessonsCount = lessonsCount;
  }
};
