import Vue from "vue";
import RadialProgressBar from "vue-radial-progress";
import VueCarousel from "vue-carousel";
import FormGroup from "./FormGroup.vue";
import Loading from "./Loading.vue";
import Snackbar from "./Snackbar.vue";
import ActionDispatcher from "./ActionDispatcher.vue";
import SearchFilter from "./SearchFilter.vue";
import DataTable from "./DataTable.vue";
import ResponsiveModal from "./ResponsiveModal.vue";
import Paginator from "./Paginator.vue";
import Card from "./Card.vue";
import InputSearchSelect from "./InputSearchSelect.vue";
import InputImage from "./InputImage.vue";
import Confirmation from "./Confirmation.vue";
import ExamAvailable from "./ExamAvailable.vue";

Vue.use(VueCarousel);
Vue.component("form-group", FormGroup);
Vue.component("loading", Loading);
Vue.component("snackbar", Snackbar);
Vue.component("action-dispatcher", ActionDispatcher);
Vue.component("search-filter", SearchFilter);
Vue.component("data-table", DataTable);
Vue.component("responsive-modal", ResponsiveModal);
Vue.component("card", Card);
Vue.component("paginator", Paginator);
Vue.component("input-search-select", InputSearchSelect);
Vue.component("input-image", InputImage);
Vue.component("confirmation", Confirmation);
Vue.component("radial-progress-bar", RadialProgressBar);
Vue.component("exam-available", ExamAvailable);
