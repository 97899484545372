<template>
  <responsive-modal v-model="showing">
    <div class="confirmation p-3">
      <div class="row mb-5 text-center">
        <div class="col-lg-5 mx-auto">
          <img class="img-fluid" src="@/assets/images/logo.png" />
        </div>
      </div>

      <h4 class="text-center px-4 pt-2" v-if="title.length">
        {{ title }}
      </h4>

      <div
        v-if="!custom"
        class="text-center text-secondary pt-2 mb-4 text"
        :class="{ 'px-2': bigText, 'px-4': !bigText }"
      >
        {{ text }}
      </div>
      <div v-else>
        <slot />
      </div>
      <div class="row">
        <div class="col-6 pt-3">
          <button
            class="btn btn-block"
            :class="cancelButtonClass"
            @click="cancel"
          >
            {{ cancelButtonText }}
          </button>
        </div>
        <div class="col-6 pt-3">
          <button
            class="btn btn-block"
            :class="confirmButtonClass"
            @click="confirm"
          >
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </div>
  </responsive-modal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    iconType: {
      type: String,
      default: "checked"
    },
    text: {
      type: String,
      default: "Tem certeza?"
    },
    title: {
      type: String,
      default: ""
    },
    cancelButtonText: {
      type: String,
      default: "Cancelar"
    },
    confirmButtonText: {
      type: String,
      default: "Confirmar"
    },
    cancelButtonClass: {
      type: String,
      default: "btn-outline-secondary"
    },
    confirmButtonClass: {
      type: String,
      default: "btn-primary"
    },
    bigText: {
      type: Boolean,
      default: () => false
    },
    custom: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.showing = false;
    },
    confirm() {
      this.$emit("confirm");
      this.showing = false;
    }
  }
};
</script>

<style lang="scss">
.confirmation {
  h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }

  .text {
    font-size: 14px;
    line-height: 17px;
  }

  button {
    font-size: 12px !important;
  }

  img {
    max-height: 100px;
  }
}
</style>
