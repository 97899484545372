<template>
  <div class="search-filter-view d-flex align-items-center">
    <div class="flex-fill">
      <div class="input-view">
        <i class="icon-search" />
        <input v-model="text" :placeholder="placeholder" />
        <div
          v-if="showResultsDropdown"
          :class="`dropdown-suggestions p-3 ${dropdownVisible}`"
        >
          <div class="d-flex justify-content-end">
            <button class="btn btn-sm close-btn" @click="close">
              <i class="icon-x" />
            </button>
          </div>
          <slot />
        </div>
      </div>
    </div>

    <div v-if="showFilters" class="pl-3">
      <button
        class="btn btn-outline-primary btn-lg-block"
        @click="dialog = true"
      >
        <i class="icon-filter mr-lg-1" />
        <div class="d-none d-lg-inline">{{ filterButtonText }}</div>
      </button>
    </div>

    <b-modal v-model="dialog" hide-header hide-footer centered>
      <div class="p-3 position-relative">
        <h5 class="text-center mb-4">{{ filterButtonText }}</h5>
        <button class="btn btn-sm btn-close" @click="dialog = false">
          <i class="icon-x" />
        </button>
        <slot name="custom-filter" :close="() => (dialog = false)" />
      </div>
    </b-modal>
  </div>
</template>

<script>
let timeout;

export default {
  props: {
    showFilters: {
      type: Boolean,
      default: false
    },
    showResultsDropdown: {
      type: Boolean,
      default: true
    },
    filterButtonText: {
      type: String,
      default: "Filtrar"
    },
    placeholder: {
      type: String,
      default: "Pesquisar por cliente"
    }
  },
  data() {
    return {
      text: "",
      loading: true,
      dropdownVisible: "d-none",
      dialog: false
    };
  },
  watch: {
    text(newVal) {
      if (newVal.length) {
        this.show();
      } else {
        this.close();
      }

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.$emit("search", newVal);
      }, 300);
    }
  },
  methods: {
    show() {
      this.dropdownVisible = "d-block";
    },
    close() {
      this.dropdownVisible = "d-none";
    }
  }
};
</script>

<style lang="scss">
.search-filter-view {
  .input-view {
    display: flex;
    align-items: center;
    background: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    padding-left: 12px;
    max-height: 50px;
    min-height: 50px;
    width: 100%;
    position: relative;
  }

  .dropdown-suggestions {
    position: absolute;
    min-width: 450px;
    background: #fff;
    top: 120%;
    left: 0;
    z-index: 2;
    border: 1px solid #d2d0d8;
    border-radius: 4px;
  }

  span {
    color: #aeb6c4;
  }

  i {
    font-size: 24px;
  }

  .close-btn {
    min-height: 20px;
    max-height: 20px;
    i {
      font-size: 16px;
    }
  }

  input {
    display: flex;
    flex: 1;
    border: none;
    width: 100%;
    background: transparent;
    outline: none;
  }

  button {
    @media screen and (max-width: 991px) {
      display: block;
      padding: 10px;
      max-height: 46px;
    }
  }
}

.btn-close {
  position: absolute;
  top: -8px;
  right: 0;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
}
</style>
