import firebase from "firebase";

// 🎉

const COLLECTION_NAME = "questions";

export default {
  post(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc()
        .set(data)
        .then(resolve)
        .catch(reject);
    });
  },

  get(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .where("module", "==", id)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          resolve(data);
        })
        .catch(reject);
    });
  },
  getQuestion(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .get()
        .then(docSnapshot => {
          resolve({ id: docSnapshot.id, ...docSnapshot.data() });
        })
        .catch(reject);
    });
  },
  put(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(data.id)
        .set(data, { merge: true })
        .then(docSnapshot => {
          resolve(docSnapshot);
        })
        .catch(reject);
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .delete()
        .then(resolve)
        .catch(reject);
    });
  },
  reorder(context, questions) {
    return new Promise(async (resolve, reject) => {
      try {
        for (let k in questions) {
          await firebase
            .firestore()
            .collection(COLLECTION_NAME)
            .doc(questions[k].id)
            .set(questions[k], { merge: true });
        }
      } catch (e) {
        reject(e);
      } finally {
        resolve();
      }
    });
  }
};
