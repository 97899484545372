// import axios from "axios";
// import Vue from "vue";
import firebase from "firebase";

// window.axios = axios;

// window.axios.defaults.baseURL = "BASE_PRODUCTION_URL";

// if (process.env.NODE_ENV === "development") {
//   window.axios.defaults.baseURL = "BASE_DEV_URL";
// }

// window.axios.defaults.timeout = 1000 * 60 * 5;

const TOKEN = "_IEB_API_TOKEN";

// const setAxiosHeader = token => {
//   if (token) {
//     window.axios.defaults.headers.common.Authorization = `JWT ${token}`;
//   } else {
//     delete window.axios.defaults.headers.common.Authorization;
//   }
// };

const firebaseConfig = {
  apiKey: "AIzaSyAcKALlOo6PwlNag64QSABVJfg-QE6y6j0",
  authDomain: "instituto-eurico-bergsten.firebaseapp.com",
  databaseURL: "https://instituto-eurico-bergsten.firebaseio.com",
  projectId: "instituto-eurico-bergsten",
  storageBucket: "instituto-eurico-bergsten.appspot.com",
  messagingSenderId: "31055920372",
  appId: "1:31055920372:web:86b8a773ac0f4889ab72a1"
};

const setToken = token => {
  localStorage.setItem(TOKEN, JSON.stringify(token));
};

const removeToken = () => {
  localStorage.removeItem(TOKEN);
};

const getToken = () => {
  return localStorage.getItem(TOKEN);
};

const init = () => {
  if (!firebase.App) {
    firebase.initializeApp(firebaseConfig);
  }
  firebase.auth().useDeviceLanguage();

  if (!firebase.auth().currentUser) {
    // window.location = `${window.location.origin}/login?path=${window.location.pathname}`;
  }
};

export { setToken, getToken, removeToken, init };
