<template>
  <div
    class="card-box-overview overview-card p-3"
    :class="{ horizontal: orientation === 'horizontal' }"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    orientation: {
      type: String,
      default: "horizontal"
    }
  }
};
</script>
<style lang="scss" scoped>
.card-box-overview {
  .horizontal {
    min-height: 88px;
    min-width: 258px;
  }

  .vertical {
    min-height: 258px;
    min-width: 88px;
  }
}
</style>
