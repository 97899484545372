<template>
  <form
    id="form-input-select"
    class="input-search-select"
    :style="{ padding: slim ? '7px' : '10px' }"
    v-on:submit.prevent="onSubmit"
  >
    <button
      v-for="(opt, index) in added"
      :key="index"
      type="button"
      class="btn btn-secondary p-2 mx-2"
      :class="{ 'btn-sm p-0': slim }"
      @click="remove(opt)"
    >
      {{ opt }}
    </button>
    <input
      id="input-tag"
      class="flex-fill p-1"
      type="text"
      v-model="innerValue"
      :placeholder="placeholder"
    />
    <div
      v-if="useSelect"
      :class="`dropdown-suggestions p-3 ${dropdownVisible}`"
    >
      <div class="d-flex justify-content-end">
        <button class="btn btn-sm close-btn" @click="close">
          <i class="icon-x" />
        </button>
      </div>
      <slot />
    </div>
  </form>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number, Object],
      default: ""
    },
    label: {
      type: String,
      default: "Digite o texto"
    },
    placeholder: {
      type: String,
      default: "Digite o texto"
    },
    slim: {
      type: Boolean,
      default: false
    },
    useSelect: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      added: [],
      innerValue: "",
      dropdownVisible: "d-none"
    };
  },
  watch: {
    value(val) {
      document.querySelector(`#input-tag`).value = val;
      this.onSubmit();
    },
    added(val) {
      this.$emit("input", val);
    },
    innerValue(val) {
      if (val.length) {
        this.show();
      } else {
        this.close();
      }
    }
  },
  methods: {
    show() {
      this.dropdownVisible = "d-block";
    },
    close() {
      this.dropdownVisible = "d-none";
    },
    onSubmit() {
      const text = document.querySelector(`#input-tag`).value;
      if (text.indexOf(",") === -1) {
        const aux = [...this.added, text];
        this.added = [];
        this.added = aux;
      } else {
        const splitted = text.split(",");
        const aux = [...this.added, ...splitted.map(t => t.trim())];
        this.added = [];
        this.added = aux;
      }
      this.innerValue = "";
    },
    remove(tag) {
      const aux = this.added.filter(t => t !== tag);
      this.added = [];
      this.added = aux;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.input-search-select {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 1px solid #928f9840;
  border-radius: 4px;

  button {
    min-height: 22px;
    max-height: 22px;
  }

  span {
    background-color: #d2d0d8;
    border-radius: 4px;
    max-height: 70%;
    cursor: pointer;
  }

  input {
    border-radius: 4px;
    height: 100%;
    min-width: 100px;
    background-color: transparent;
    border: 0;
    outline: none;
  }

  .btn-secondary {
    font-weight: 400;
  }

  .dropdown-suggestions {
    position: absolute;
    min-width: 450px;
    background: #fff;
    top: 120%;
    left: 0;
    z-index: 2;
    border: 1px solid #d2d0d8;
    border-radius: 4px;
  }

  .close-btn {
    min-height: 20px;
    max-height: 20px;
    i {
      font-size: 16px;
    }
  }
}
</style>
