import { setToken } from "../../setup/api";
import firebase from "firebase";

// 🎉

export default {
  login(context, { email, password }) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(response => {
          setToken(response.user);
          context.commit("setUser", response.user);
          context.dispatch("get", { root: true });
          context.commit("setLogged", true);
          resolve();
        })
        .catch(reject);
    });
  },

  register(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(data.email, data.password)
        .then(response => {
          firebase
            .firestore()
            .collection("user-data")
            .doc(data.email)
            .set(data)
            .then();
          firebase
            .auth()
            .signInWithEmailAndPassword(data.email, data.password)
            .then(() => {
              setToken(response.user);
              context.commit("setLogged", true);
              context.dispatch("postLogin", null, { root: true });
              resolve();
            });
        })
        .catch(reject);
    });
  },

  forgot(context, email) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(resolve)
        .catch(reject);
    });
  },

  resetPassword(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .confirmPasswordReset(data.code, data.password)
        .then(resolve)
        .catch(reject);
    });
  },

  get(context) {
    return new Promise((resolve, reject) => {
      const onSuccess = async doc => {
        const data = { id: doc.id, ...doc.data() };
        context.commit("setUser", data);

        if (window.FS) {
          window.FS.identify(doc.data().email, {
            displayName: doc.data().name,
            email: doc.data().email
          });
        }
        const docSnapshotClass = await firebase
          .firestore()
          .collection("classes")
          .doc(data.class)
          .get();

        context.commit("setClassInfo", {
          id: docSnapshotClass.id,
          ...docSnapshotClass.data()
        });

        const docSnapshotProgress = await firebase
          .firestore()
          .collection("progress")
          .doc(doc.data().email)
          .get();
        context.commit("updateUser", {
          progress: docSnapshotProgress.data()
        });

        resolve();
      };
      const onError = error => {
        setToken(null);
        reject(error);
      };
      const loggedUser = firebase.auth().currentUser;
      if (loggedUser) {
        firebase
          .firestore()
          .collection("user-data")
          .doc(loggedUser.email)
          .get()
          .then(onSuccess)
          .catch(onError);
      } else {
        firebase.auth().onAuthStateChanged(user => {
          if (user === null) {
            setToken(null);
            reject();
          } else {
            firebase
              .firestore()
              .collection("user-data")
              .doc(user.email)
              .get()
              .then(onSuccess)
              .catch(onError);
          }
        });
      }
    });
  },

  update(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("user-data")
        .doc(data.email)
        .set(data, { merge: true })
        .then(function() {
          context.commit("updateUser", data);
          context.dispatch("get");
          resolve();
        })
        .catch(reject);
    });
  },
  updatePassword(context, newPassword) {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;
      currentUser
        .updatePassword(newPassword)
        .then(() => {
          context.dispatch("update", {
            ...currentUser,
            password: newPassword
          });
          resolve();
        })
        .catch(reject);
    });
  },
  updateProgress(context, { progress, email }) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("progress")
        .doc(email)
        .set(progress, { merge: true })
        .then(() => {
          context.commit("updateUser", { progress });
          resolve();
        })
        .catch(reject);
    });
  },
  uploadUserImg(context, { file, email, name }) {
    return new Promise((resolve, reject) => {
      const root = firebase.storage().ref();

      const ref = root.child(`user/${email}/img/${name}`);

      ref
        .putString(file, "data_url")
        .then(snapshot => {
          ref.getDownloadURL().then(url => {
            resolve(url);
          });
        })
        .catch(e => {
          reject(e);
        });
    });
  }
};
