import Vuex from "vuex";
import Vue from "vue";

import mutations from "./mutations";
import actions from "./actions";
import state from "./state";

import bibliography from "./bibliography";
import questions from "./questions";
import homeworks from "./homeworks";
import answers from "./answers";
import content from "./content";
import classes from "./classes";
import user from "./user";
import exam from "./exam";

Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  state,
  mutations,
  modules: {
    bibliography,
    questions,
    homeworks,
    answers,
    content,
    classes,
    user,
    exam
  }
});
