<template>
  <div class="responsive-select" :class="{ 'has-value': value }">
    <!-- mobile -->
    <div class="d-lg-none">
      <button
        type="button"
        class="btn text-left btn-block"
        :disabled="disabled"
        @click="show = true && !disabled"
      >
        <div
          class="form-control text-truncate d-flex align-items-center"
          :class="{
            'ultra-slim-size': ultraSlim,
            'slim-size': slim
          }"
        >
          {{ valueDisplayed }}
        </div>
      </button>
      <responsive-modal v-model="show">
        <label v-if="label.length" class="font-weight-bold">
          {{ label }}
        </label>
        <div class="scroll-content overflow-y-scroll">
          <div
            v-for="(option, index) in options"
            :key="index"
            @click="onClickItem(option, index)"
            class="d-flex align-items-center"
          >
            <div
              class="btn btn-block p-0 justify-content-start text-left font-weight-light selectable-item flex-fill "
            >
              {{ option.name }}
            </div>
            <div v-if="selectionType === 'radio'" class="">
              <input
                :id="option.id"
                type="radio"
                :value="option.id"
                v-model="selected[index]"
                class="mr-2 pt-1 radio-input"
              />
            </div>
          </div>
        </div>
      </responsive-modal>
    </div>
    <!-- mobile -->

    <!-- desktop -->
    <b-dropdown
      :disabled="disabled"
      class="form-control d-none d-lg-flex align-items-center"
      :text="`${valueDisplayed}`"
      :class="{
        'is-invalid': showError
      }"
      :toggle-class="{
        'text-secondary': value === placeholder || !value,
        'text-dark': value && value !== placeholder,
        'text-decoration-none text-truncate text-secondary': true,
        'ultra-slim-size': ultraSlim,
        'slim-size': slim
      }"
    >
      <div class="scroll-content overflow-y-scroll">
        <b-dropdown-item
          v-for="(option, index) in options"
          :key="index"
          @click="onClickItem(option, index)"
        >
          {{ option.name }}
        </b-dropdown-item>
      </div>
    </b-dropdown>
    <!-- desktop -->

    <div v-if="showError" class="invalid-feedback">
      {{ errors[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsiveSelect",
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Selecione..."
    },
    value: {
      type: [Object, String, Number],
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    selectionType: {
      type: String,
      default: "radio"
    },
    errors: {
      type: Array,
      default: () => []
    },
    slim: {
      type: Boolean,
      default: false
    },
    ultraSlim: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false,
      loading: false,
      innerValue: this.value ? this.value : this.placeholder,
      selected: { ...Array(this.options.length).fill(false) },
      showError: this.errors.length
    };
  },
  watch: {
    innerValue(val) {
      this.$emit("input", val);
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
        this.showError = false;
      }
    }
  },
  computed: {
    valueDisplayed() {
      const value = this.options.find(o => `${o.id}` === `${this.innerValue}`);
      if (!value) {
        return this.innerValue;
      }
      return value.name;
    }
  },
  methods: {
    onClickItem(option, index) {
      this.showError = false;
      this.show = false;
      this.innerValue = option.id;
      this.selected = { ...Array(this.options.length).fill(false) };
      this.selected[index] = option.id;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.responsive-select {
  .form-control {
    background: #fff !important;
    min-height: 50px;
  }
  .btn {
    padding: 0;
    font-weight: 400 !important;
  }

  ::v-deep .dropdown-toggle {
    padding: 0;
    border: none;
    text-align: left;
    background: #fff;

    &::after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      border-top: 0.3em solid #000;
    }
  }
  ::v-deep.dropdown .btn {
    min-height: 14px;
    justify-content: flex-start;
    font-weight: 400;
    background: #fff;
    padding-right: 15px;
  }

  ::v-deep.presentation {
    word-wrap: break-word;
  }

  ::v-deep.dropdown-menu.show {
    flex-grow: 1;
    min-width: 100px !important;
    width: fit-content;
  }

  &.has-value {
    ::v-deep .dropdown-toggle {
      color: #495057;
    }
  }

  ::v-deep .dropdown-item {
    font-size: 14px;
    color: #495057;
  }
  ::v-deep .dropdown-item:active {
    color: #fff !important;
  }

  .scroll-content {
    max-height: 300px;

    @media screen and (max-width: 991px) {
      max-height: 600px;
    }
  }

  ::v-deep.modal-content {
    @media screen and (max-width: 991px) {
      height: 600px !important;
      max-height: 600px !important;
    }
  }

  .slim-size {
    min-height: 28px !important;
    max-height: 40px !important;
    ::v-deep.btn {
      min-height: 28px !important;
      max-height: 40px !important;
    }
  }

  .ultra-slim-size {
    min-height: 30px !important;
    max-height: 35px !important;
    font-size: 12px !important;
    ::v-deep.btn {
      min-height: 30px !important;
      max-height: 35px !important;
      font-size: 12px !important;
    }
  }

  label {
    font-family: Poppins;
    font-weight: 400;
    color: #595463;
    font-size: 14px;
  }

  .dropdown.form-control {
    background: white;
  }

  button {
    .form-control {
      background: white !important;
      appearance: none !important;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
      background-repeat: no-repeat !important;
      background-position-x: 100% !important;
      background-position-y: center !important;
      padding-right: 20px !important;
      .selectable-item {
        border-bottom: 1px solid #00000020;
      }
      .selectable-item:active {
        background: #9155ec20;
        color: #fff;
      }
    }
  }
}
</style>
