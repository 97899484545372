import { render, staticRenderFns } from "./ResponsiveSelect.vue?vue&type=template&id=08cf8910&scoped=true&"
import script from "./ResponsiveSelect.vue?vue&type=script&lang=js&"
export * from "./ResponsiveSelect.vue?vue&type=script&lang=js&"
import style0 from "./ResponsiveSelect.vue?vue&type=style&index=0&id=08cf8910&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08cf8910",
  null
  
)

export default component.exports