import firebase from "firebase";
// 🎉

export default {
  getModules(context) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("modules")
        .get()
        .then(snapshot => {
          const list = [];
          snapshot.forEach(doc => {
            list.push({
              id: doc.id,
              ...doc.data()
            });
          });
          const sorted = list.sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            }
            if (a.index > b.index) {
              return 1;
            }
            return 0;
          });
          context.commit("setModules", sorted);
          resolve(sorted);
        })
        .catch(reject);
    });
  },
  getModule(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("modules")
        .doc(id)
        .get()
        .then(doc_snapshot => {
          resolve({ id: doc_snapshot.id, ...doc_snapshot.data() });
        })
        .catch(reject);
    });
  },
  getLesson(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("modules")
        .doc(id)
        .collection("class")
        .get()
        .then(snapshot => {
          const list = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          const sorted = list.sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            }
            if (a.index > b.index) {
              return 1;
            }
            return 0;
          });
          resolve(sorted);
        })
        .catch(reject);
    });
  }
};
