<template>
  <div
    class="form-group slim-size"
    :class="{
      'has-icon': !!icon,
      'has-value': !!value,
      'is-date': isDate
    }"
  >
    <label :for="id" v-if="label && type !== 'file' && !isSwitch">
      {{ label }}
    </label>
    <i v-if="icon" :class="'icon ' + icon"></i>

    <div v-if="isDate" class="date-picker-wrapper d-flex">
      <v-date-picker
        :first-day-of-week="1"
        v-model="date"
        :popover="{ placement: 'bottom', visibility: 'click' }"
      >
        <template v-slot="{ togglePopover }">
          <button
            type="button"
            class="btn text-secondary"
            @click="togglePopover({ placement: 'auto-start' })"
          >
            <i class="icon-landing-page"></i>
          </button>
        </template>
      </v-date-picker>
    </div>

    <responsive-select
      v-if="select"
      :id="id"
      :slim="slim"
      :ultraSlim="ultraSlim"
      :label="label"
      v-model="innerValue"
      :class="classObject"
      :disabled="disabled"
      :placeholder="placeholder"
      :options="options"
    />

    <b-form-checkbox-group
      v-else-if="checkboxes"
      v-model="innerValue"
      :id="id"
      :class="classObject"
    >
      <b-form-checkbox
        v-for="(option, index) in options"
        :key="index"
        :value="option.id"
        class="d-block"
      >
        {{ optionLabelFunction(option) }}
      </b-form-checkbox>
    </b-form-checkbox-group>
    <the-mask
      v-else-if="mask.length"
      :mask="mask"
      :masked="masked"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div
      v-else-if="isSwitch"
      class="custom-control custom-switch"
      :class="{ disabled }"
    >
      <input
        type="checkbox"
        v-model="innerValue"
        class="custom-control-input"
        :id="id"
        :disabled="disabled"
      />
      <label class="custom-control-label" :for="id">
        {{ label }}
      </label>
    </div>
    <money
      v-else-if="isMoney"
      v-model="innerValue"
      class="form-control"
      v-bind="moneyParams"
      :class="classObject"
      :id="id"
      :type="type"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <vue-tel-input
      v-else-if="isPhone"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      type="tel"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      @country-changed="countryChanged"
      @validate="obj => $emit('validate', obj)"
    />
    <textarea
      v-else-if="textarea"
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :readonly="readonly"
      :disabled="disabled"
      :rows="rows"
    />
    <div class="form-password-wrapper" v-else-if="type === 'password'">
      <input
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="!showPassword && type"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
      />
      <button
        v-if="!showError"
        type="button"
        class="show-password-icon clear-button pt-1"
        @click="showPassword = !showPassword"
      >
        <i :class="!showPassword ? 'icon-eye' : 'icon-eye-off'" />
      </button>
    </div>

    <div class="radio-group text-dark" v-else-if="type === 'radio'">
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio
          class="mb-1"
          :class="classObject"
          v-for="option in options"
          :key="option.name"
          :id="`${option.id}`"
          :aria-describedby="ariaDescribedby"
          :value="option.id"
          v-model="innerValue"
        >
          {{ option.name }}
        </b-form-radio>
      </b-form-group>
    </div>

    <div class="d-flex align-items-center" v-else-if="isInputPrefix">
      <span class="font-weight-bold pr-2">{{ prefix }}</span>
      <input
        v-model="innerValue"
        class="form-control"
        :class="classObject"
        :id="id"
        :type="type"
        :maxLength="maxLength"
        :placeholder="placeholder"
        :autocomplete="disableAutocomplete ? 'off' : ''"
        :autocorrect="disableAutocapitalize ? 'off' : ''"
        :autocapitalize="disableAutocapitalize ? 'none' : ''"
        :readonly="readonly"
        :disabled="disabled"
      />
    </div>

    <input
      v-else
      v-model="innerValue"
      class="form-control"
      :class="classObject"
      :id="id"
      :type="type"
      :maxLength="maxLength"
      :placeholder="placeholder"
      :autocomplete="disableAutocomplete ? 'off' : ''"
      :autocorrect="disableAutocapitalize ? 'off' : ''"
      :autocapitalize="disableAutocapitalize ? 'none' : ''"
      :readonly="readonly"
      :disabled="disabled"
    />
    <div
      v-if="showError && showErrorMessage"
      class="invalid-feedback"
      :class="{ 'd-block': showError }"
    >
      {{ errors[0] }}
    </div>

    <button
      v-if="color"
      class="color-button"
      @click="showColorPicker = true"
      :style="`background-color: ${innerValue}`"
    />

    <div
      v-if="showColorPicker"
      class="color-picker"
      v-click-outside="hideColorPicker"
    >
      <chrome-picker @input="updateColor" :value="innerValue" />
    </div>

    <button
      v-if="withClearButton && value && value.length"
      class="clear"
      @click="clear"
    >
      <i class="icon-x"></i>
    </button>
  </div>
</template>

<script>
import vClickOutside from "v-click-outside";
import ResponsiveSelect from "@/components/ResponsiveSelect";
import { Money } from "v-money";
import { Chrome } from "vue-color";

export default {
  components: { Money, ResponsiveSelect, "chrome-picker": Chrome },
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    prefix: {
      type: String,
      default: ""
    },
    nullable: {
      type: Boolean,
      default: false
    },
    mask: {
      type: [String, Array],
      default: ""
    },
    masked: {
      type: Boolean,
      default: false
    },
    isMoney: {
      type: Boolean,
      default: false
    },
    isInputPrefix: {
      type: Boolean,
      default: false
    },
    isDate: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    },
    select: {
      type: Boolean,
      default: false
    },
    checkboxes: {
      type: Boolean,
      default: false
    },
    textarea: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    optionLabelFunction: {
      type: Function,
      default: item => item.name
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "text"
    },
    placeholder: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number, Boolean, Array],
      default: ""
    },
    customClass: {
      type: String,
      default: ""
    },
    showErrorMessage: {
      type: Boolean,
      default: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    disableAutocapitalize: {
      type: Boolean,
      default: false
    },
    disableAutocomplete: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withClearButton: {
      type: Boolean,
      default: false
    },
    isLogin: {
      type: Boolean,
      default: false
    },
    rows: {
      type: String,
      default: ""
    },
    isSwitch: {
      type: Boolean,
      default: false
    },
    slim: {
      type: Boolean,
      default: false
    },
    ultraSlim: {
      type: Boolean,
      default: false
    },
    color: {
      type: Boolean,
      default: false
    },
    moneyConfig: {
      type: Object,
      default: () => {}
    },
    maxLength: {
      type: Number,
      default: 524288
    }
  },
  data() {
    return {
      showColorPicker: false,
      showPassword: false,
      showError: this.errors ? this.errors.length : 0,
      innerValue: this.value,
      date: null
    };
  },
  computed: {
    moneyParams() {
      return {
        precision: 2,
        prefix: `R$ `,
        decimal: ",",
        thousands: ".",
        ...this.moneyConfig
      };
    },
    classObject() {
      const result = {
        "is-invalid": this.showError,
        "slim-size": this.slim,
        "ultra-slim-size": this.ultraSlim
      };
      result[this.customClass] = true;
      return result;
    }
  },
  watch: {
    date(newVal) {
      this.innerValue = newVal
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/");
    },
    errors(newVal) {
      if (newVal && !!newVal[0]) {
        this.showError = true;
      } else {
        this.showError = false;
      }
    },
    innerValue(newVal) {
      if (newVal != this.value) {
        this.showError = false;
        this.$emit("input", newVal);

        if (this.isDate && newVal && newVal.length === 10) {
          this.date = new Date(
            newVal
              .split("/")
              .reverse()
              .join("-") + "T12:00:00"
          );
        }
      }
      if (this.isPhone && newVal.match(/[a-zA-Z]/)) {
        setTimeout(() => {
          this.innerValue = newVal.replace(/[a-zA-Z]/, "");
        }, 2);
      }
    },
    value(newVal) {
      if (this.innerValue !== newVal) {
        this.innerValue = newVal;
        this.showError = false;
      }
    }
  },
  methods: {
    updateColor(obj) {
      this.innerValue = obj.hex;
    },
    hideColorPicker() {
      this.showColorPicker = false;
    },
    countryChanged(obj) {
      this.$emit("country-changed", obj);
      setTimeout(() => {
        this.innerValue = document.querySelector(`#${this.id} input`).value;
      }, 1);
    },
    clear() {
      this.$emit("input", "");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.form-group {
  position: relative;
  margin-bottom: 0;
  padding: 8px 0;

  .slim-size {
    min-height: 28px;
    max-height: 50px;
  }

  .ultra-slim-size {
    min-height: 30px;
    max-height: 35px;
    font-size: 12px;
  }

  select.form-control {
    background: white;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: center;
    padding-right: 20px;
  }

  &.has-icon {
    .icon {
      position: absolute;
      line-height: 54px;
      left: 4px;
    }

    .form-control {
      padding-right: 32px;
    }
  }

  .clear {
    position: absolute;
    height: 64px;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
  }

  &:not(.has-value) {
    select {
      color: #aeb6c4 !important;
    }
  }

  ::v-deep .vti__dropdown-list {
    z-index: 3;
  }

  .date-picker-wrapper {
    position: absolute;
    top: 34px;
    left: 0;
    z-index: 1;

    button {
      height: 54px;
    }
  }

  &.is-date {
    .form-control {
      padding-left: 40px;
    }
  }

  .form-password-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .form-control {
      padding-right: 7px;
    }
    .show-password-icon {
      position: absolute;
      right: 25px;
      top: 50%;
      font-size: 17px;
      cursor: pointer;
      user-select: none;
    }
    .clear-button {
      background-color: transparent;
      padding: 0px;
      margin: 0px;
      border: 0px;
    }
  }

  input[type="checkbox"].form-control {
    transform: scale(1.1);
    min-height: 10px;
  }

  input[type="checkbox"]:not(.custom-switch) {
    height: 10px;
    cursor: pointer;
    font-size: 17px;
    visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="checkbox"]:not(.custom-switch):after {
    content: " ";
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary;
    width: 16px;
    height: 16px;
    visibility: visible;
    border: 1px solid #928f9890;
    border-radius: 4px;
  }

  .color-button {
    position: absolute;
    top: 49px;
    right: 8px;
    min-height: 28px;
    min-width: 28px;
    max-height: 28px;
    max-width: 28px;
    border-radius: 28px;
    align-items: center;
    border: none;
    background-color: black;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .color-picker {
    position: absolute;
    right: 0;
    z-index: 90;
  }

  textarea {
    resize: both;
    min-height: 40px !important;
    max-height: 200px !important;
  }

  .custom-switch .custom-control-label::after {
    width: calc(1.25rem - 4px);
    height: calc(1.25rem - 4px);
    left: calc(-2.08rem + 5px);
    top: calc(0.3rem + 1.6px);
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border-radius: 50%;
  }

  &:not(.has-value) .custom-switch .custom-control-label::after {
    left: calc(-2.08rem + 1px);
  }

  .custom-switch .custom-control-label::before {
    height: 1.3rem;
    width: 2.4rem;
    border-radius: 2.5rem;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-image: linear-gradient(
      148.5deg,
      rgba($color: #ee6469, $alpha: 1) -19.31%,
      rgba($color: #9155ec, $alpha: 1) 100%
    ) !important;
    border-color: transparent;
  }

  .custom-switch.disabled {
    opacity: 0.5;

    label {
      cursor: not-allowed;
    }
  }

  ::v-deep .vue-tel-input {
    display: flex;

    input {
      background: transparent;
    }
  }
}
</style>
