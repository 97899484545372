import firebase from "firebase";
// 🎉

export default {
  get(context) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("exams")
        .get()
        .then(snapshot => {
          const list = [];
          snapshot.forEach(doc => {
            list.push({
              id: doc.id,
              ...doc.data()
            });
          });
          const sorted = list.sort((a, b) => {
            if (a.index < b.index) {
              return -1;
            }
            if (a.index > b.index) {
              return 1;
            }
            return 0;
          });
          context.commit("setExams", sorted);
          resolve(sorted);
        })
        .catch(reject);
    });
  },
  submit(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("answers")
        .doc()
        .set({
          ...data,
          created_at_timestamp: firebase.firestore.Timestamp.now()
        })
        .then(resolve)
        .catch(reject);
    });
  }
};
