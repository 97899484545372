import firebase from "firebase";

// 🎉

const COLLECTION_NAME = "classes";

export default {
  post(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc()
        .set(data)
        .then(resolve)
        .catch(reject);
    });
  },

  get(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          context.commit("setClasses", data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  getClass(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .get()
        .then(docSnapshot => {
          resolve({ id: docSnapshot.id, ...docSnapshot.data() });
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getStudents(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("user-data")
        .where("class", "==", id)
        .get()
        .then(docSnapshot => {
          const data = docSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  put(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(data.id)
        .set(data, { merge: true })
        .then(docSnapshot => {
          resolve(docSnapshot);
        })
        .catch(reject);
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .delete()
        .then(resolve)
        .catch(reject);
    });
  }
};
