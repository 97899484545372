import firebase from "firebase";
import { normalizeText } from "@/functions/helpers";

// 🎉

const COLLECTION_NAME = "homeworks";

export default {
  get(context, class_id) {
    return new Promise((resolve, reject) => {
      const LIMIT = 20;
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .where("class_id", "==", class_id)
        .orderBy("created_at")
        .startAfter(context.state.lastDoc || null)
        .limit(LIMIT)
        .get()
        .then(snapshot => {
          let data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          context.commit("setHomeworks", data);
          if (snapshot.docs[snapshot.docs.length - 1]) {
            context.commit(
              "setLastDoc",
              snapshot.docs[snapshot.docs.length - 1]
            );
          }
          resolve(data);
        })
        .catch(e => {
          reject(e);
        });
    });
  },
  getHomework(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .get()
        .then(docSnapshot => {
          resolve({ id: docSnapshot.id, ...docSnapshot.data() });
        })
        .catch(reject);
    });
  },
  getUserHomeworks(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .where("user_id", "==", id)
        .get()
        .then(snapshot => {
          const data = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
          resolve(data);
        })
        .catch(reject);
    });
  },
  search(context, search) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .get()
        .then(snapshot => {
          const data = snapshot.docs
            .filter(
              doc =>
                normalizeText(JSON.stringify(doc.data())).indexOf(search) > -1
            )
            .map(d => ({ id: d.id, ...d.data() }));

          resolve(data);
        })
        .catch(reject);
    });
  },
  post(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc()
        .set(data)
        .then(resolve)
        .catch(reject);
    });
  },
  put(context, data) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(data.id)
        .set(data, { merge: true })
        .then(docSnapshot => {
          resolve(docSnapshot);
        })
        .catch(reject);
    });
  },
  delete(context, id) {
    return new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection(COLLECTION_NAME)
        .doc(id)
        .delete()
        .then(resolve)
        .catch(reject);
    });
  }
};
