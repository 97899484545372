import Vue from "vue";
import Router from "vue-router";
// import VueGtm from "vue-gtm";

Vue.use(Router);

const vueRouter = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/cadastro",
      name: "register",
      component: () => import("./views/auth/Register.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/auth/Login.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/esqueci-a-senha",
      name: "forgot-password",
      component: () => import("./views/auth/ForgotPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    {
      path: "/mudar-senha",
      name: "reset-password",
      component: () => import("./views/auth/ResetPassword.vue"),
      meta: {
        template: () => import("./templates/Auth.vue"),
        protected: false
      }
    },
    // EXTRA PAGES
    {
      path: "/termos-de-uso",
      name: "terms",
      component: () => import("./views/Terms.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/ajuda",
      name: "help",
      component: () => import("./views/Help.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/suporte",
      name: "support",
      component: () => import("./views/Support.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
      meta: {
        template: () => import("./templates/Blank.vue"),
        protected: false
      }
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Ínicio",
        protected: true
      }
    },
    {
      path: "/conteudo",
      name: "content",
      component: () => import("./views/content/Index.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Conteúdo do curso",
        protected: true
      }
    },
    {
      path: "/provas",
      name: "exams",
      component: () => import("./views/exams/Index.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Provas",
        protected: true
      }
    },
    {
      path: "/bibliografia",
      name: "bibliography",
      component: () => import("./views/bibliography/Index.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Bibliografia",
        protected: true
      }
    },
    {
      path: "/perfil",
      name: "profile",
      component: () => import("./views/profile/Account.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Perfil",
        protected: true
      }
    },
    // ADMIN ROUTES
    {
      path: "/administrativo/provas",
      name: "admin-exams",
      component: () => import("./views/admin/Exams.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Provas",
        protected: true
      }
    },
    {
      path: "/administrativo/provas/modulo/:id",
      name: "admin-module-questions",
      component: () => import("./views/admin/ModuleQuestions.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Provas",
        protected: true
      }
    },
    {
      path: "/administrativo/adicionar/provas",
      name: "admin-new-exam",
      component: () => import("./views/admin/NewQuestion.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Provas",
        protected: true
      }
    },
    {
      path: "/administrativo/editar/prova/:id",
      name: "admin-edit-question",
      component: () => import("./views/admin/NewQuestion.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Provas",
        protected: true
      }
    },
    {
      path: "/administrativo/turmas",
      name: "admin-classes",
      component: () => import("./views/admin/Classes.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Turmas",
        protected: true
      }
    },
    {
      path: "/administrativo/turma/:id",
      name: "admin-class",
      component: () => import("./views/admin/ClassStudents.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Turmas",
        protected: true
      }
    },
    {
      path: "/administrativo/adicionar/turmas",
      name: "admin-new-class",
      component: () => import("./views/admin/NewClass.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Turmas",
        protected: true
      }
    },
    {
      path: "/administrativo/editar/turma/:id",
      name: "admin-edit-class",
      component: () => import("./views/admin/NewClass.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Turmas",
        protected: true
      }
    },
    {
      path: "/administrativo/atividades/",
      name: "admin-homeworks",
      component: () => import("./views/admin/Homework.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Atividades",
        protected: true
      }
    },
    {
      path: "/administrativo/atividades/class/:id",
      name: "admin-class-homework",
      component: () => import("./views/admin/ClassHomeworks.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Atividades",
        protected: true
      }
    },
    {
      path: "/administrativo/atividade/:id/respostas",
      name: "admin-homework-answers",
      component: () => import("./views/admin/HomeworkAnswers.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Respostas da atividade",
        protected: true
      }
    },
    {
      path: "/administrativo/atividade/resposta/:id",
      name: "admin-homework-answer",
      component: () => import("./views/admin/HomeworkAnswer.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Resposta da atividade",
        protected: true
      }
    },
    {
      path: "/administrativo/nova/atividade",
      name: "admin-new-homework",
      component: () => import("./views/admin/NewHomework.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Atividades",
        protected: true
      }
    },
    {
      path: "/administrativo/editar/atividade/:id",
      name: "admin-edit-homework",
      component: () => import("./views/admin/NewHomework.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Atividades",
        protected: true
      }
    },
    {
      path: "/administrativo/prova/respostas/",
      name: "admin-answers",
      component: () => import("./views/admin/Answers.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Respostas",
        protected: true
      }
    },
    {
      path: "/administrativo/prova/resposta/:id",
      name: "admin-user-answer",
      component: () => import("./views/admin/UserAnswers.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Respostas",
        protected: true
      }
    },
    // Provas
    {
      path: "/turma/:class_id/prova/:id",
      name: "test-exam",
      component: () => import("./views/TestExam.vue"),
      meta: {
        template: () => import("./templates/Default.vue"),
        title: "Prova",
        protected: true
      }
    }
  ]
});

export default vueRouter;
