<template>
  <div class="data-table">
    <div v-if="!data.length" class="pt-4">
      <slot name="empty" />
    </div>
    <div v-else>
      <div
        class="grid-table mb-2"
        :style="{ 'grid-template-columns': gridFractions }"
      >
        <small
          v-for="(column, ic) in columns"
          :key="ic"
          class="text-secondary font-weight-bold"
          :class="{
            'pl-2': ic === 0 && firstTitlePadding,
            'mr-1': ic === 0,
            'ml-1': ic === columns.length - 1,
            'mx-1': ic !== 0 && ic !== columns.length - 1
          }"
        >
          {{ column }}
        </small>
      </div>
      <div
        v-for="(item, index) in data"
        :key="index"
        class="grid-table item "
        :class="{
          'item-border': showSeparator,
          'py-3': !slim
        }"
        :style="{
          'grid-template-columns': gridFractions
        }"
      >
        <slot :item="item" :i="index" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    slim: {
      type: Boolean,
      default: false
    },
    expandOnHover: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    lastColumnRight: {
      type: Boolean,
      default: true
    },
    showSeparator: {
      type: Boolean,
      default: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    data: {
      type: Array,
      default: () => []
    },
    firstColumnFr: {
      type: Number,
      default: 3
    },
    lastColumnFr: {
      type: Number,
      default: 0.5
    },
    firstTitlePadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    gridFractions() {
      const fr_list = Array(this.columns.length - 2)
        .fill("1fr")
        .join(" ");
      return `${this.firstColumnFr}fr ${fr_list} ${this.lastColumnFr}fr`;
    }
  }
};
</script>

<style lang="scss" scoped>
.data-table {
  .grid-table {
    display: grid;
  }

  .item,
  .item-border {
    min-height: 58px;
  }

  .item-border {
    &:not(:last-child) {
      border-bottom: 1px solid #d2d0d840;
    }
  }
}
</style>
